import type { AppProps } from 'next/app';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/next';

import '@fortawesome/fontawesome-svg-core/styles.css';
import '@/styles/style.scss';

import { config } from '@fortawesome/fontawesome-svg-core';

// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false;

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Component {...pageProps} />
      <Analytics />
      <SpeedInsights />
    </>
  );
}
